import React from "react";

const Error404: React.FC = (props: any) => {
    return (
        <>
            <h1>Error 404</h1>
        </>
    );
};

export default Error404;
