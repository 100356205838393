import React, { useLayoutEffect, useState } from "react";
import { NavBar } from "antd-mobile";

export const TopBarLayout: React.FC = () => {
    const [enableDarkMode, setEnableDarkMode] = useState(true);

    useLayoutEffect(() => {
        document.documentElement.setAttribute(
            "data-prefers-color-scheme",
            enableDarkMode ? "dark" : "light"
        );
    }, [enableDarkMode]);

    return <>{/* <NavBar backArrow={false}></NavBar> */}</>;
};

export default TopBarLayout;
