import createSagaMiddleware from "redux-saga";
import { applyMiddleware, createStore, compose } from "redux";
import rootReducer from "./reducers";
// import myMiddleware from "./middleware";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers =
    typeof window === "object" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;
// const store = createStore(rootReducer, composeEnhancers(applyMiddleware(myMiddleware, sagaMiddleware)));
const store = createStore(rootReducer, composeEnhancers(applyMiddleware()));
// sagaMiddleware.run(mainSaga);

export default store;
