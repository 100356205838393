import axios, { AxiosResponse, AxiosError } from "axios";
import { Toast } from "antd-mobile";

// const BASE_URL: string =
//     process.env.NODE_ENV === "development"
//         ? "http://127.0.0.1:8080/api/v1"
//         : "https://api-work.sakuraxx.com/api/v1";

// const BASE_URL = "http://127.0.0.1/api/v1";
const BASE_URL = "https://api-work.sakuraxx.com/api/v1";

const codeMessage: { [key: number]: string } = {
    200: "OK",
    201: "新建或修改数据成功。",
    202: "一个请求已经进入后台排队（异步任务）。",
    204: "Delete successfully",
    400: "Bad Request",
    401: "Unauthorized",
    403: "Forbidden",
    404: "Not Found",
    406: "Invalid Format",
    410: "Resource Removed.",
    422: "Validation Error",
    500: "Internal Server Error",
    502: "Gateway Error",
    503: "Service Shutdown. Server Overload or Maintaince.",
    504: "Timeout"
};

const config = {
    baseURL: BASE_URL,
    timeout: 1000 * 15,
    withCredentials: false,
    headers: {
        "Content-Type": "application/json;chartset=UTF-8"
    }
};

const instance = axios.create(config);
instance.interceptors.request.use(
    config => {
        console.log(config);
        // Do something before request is sent
        return config;
    },
    error => {
        // Do something with request error
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response: AxiosResponse) => {
        // if (response.data) {}
        // Do something before response is sent
        return response && response.data;
    },
    (error: AxiosError) => {
        const { response } = error;
        if (response && response.status) {
            const errorText =
                codeMessage[response.status] || response.statusText;
            const { status, config } = response;
            Toast.show({
                icon: "fail",
                // content: `Request error ${status}: ${config.url}`
                content: `${codeMessage[status]}`
            });
            // if (response.status === 401 || response.status === 403) {}
        } else if (!response) {
            Toast.show({
                icon: "fail",
                content: "Unknown Status"
            });
        }

        // Do something with response error
        return Promise.reject(error);
    }
);

export default instance;
