import ajax from "@/utils/request/ajax";
import {
    SpreadSheetData,
    AuthData,
    PriceListParams,
    PriceListData
} from "@/types/api";

const request = ajax;

const spreadSheetData = (authData: AuthData) =>
    request.post("/data", authData) as Promise<SpreadSheetData>;
// const currencyChange = () => request.get("") as Promise<>;

const getPricelist = (priceListParams: PriceListParams) =>
    request.get(
        "/price-list?shopId=" + priceListParams.shopId
    ) as Promise<PriceListData>;

export { spreadSheetData, getPricelist };
