import React from "react";
import { Outlet } from "react-router-dom";
// import TabLayout from "./TabLayout";
import TopBarLayout from "./TopBarLayout";

// export const BlankLayout: React.FC = (props: any) => {
//     return <>{props.children}</>;
// };

export const BlankLayout: React.FC = () => {
    return (
        <>
            <TopBarLayout />
            <Outlet />
            {/* <TabLayout /> */}
        </>
    );
};

export default BlankLayout;
