import React, { useLayoutEffect, useState } from "react";
import "./App.css";
import Main from "@/router";
// import "antd/dist/antd.css"; // Import default Ant Design styles
import "./theme.less"; // Import your custom theme styles
import { ConfigProvider } from "antd-mobile";

const App: React.FC = () => {
    const [enableDarkMode, setEnableDarkMode] = useState(true);

    useLayoutEffect(() => {
        document.documentElement.setAttribute(
            "data-prefers-color-scheme",
            enableDarkMode ? "dark" : "light"
        );
    }, [enableDarkMode]);

    return (
        <>
            <Main />
        </>
    );
};

export default App;
