import React from "react";
import { Route, Routes } from "react-router-dom";
import BlankLayout from "@/layout/BlankLayout";
import Home from "@/pages/Home";
import Error404 from "@/pages/Error/404";

export default function RouterComponent() {
    return (
        <Routes>
            <Route path="/" element={<BlankLayout />}>
                <Route path="/" element={<Home />} />
            </Route>
            <Route path="*" element={<Error404 />} />
        </Routes>
    );
}
